import { useMutation, useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import {
  AiAnimatedElementsGeneration,
  AiAnimatedElementsGenerationStatus,
  TaskStatus,
} from "../../__generated__/graphql";
import { MY_GENERATIONS } from "./generationList";
import { removeOneCredit } from "./profile";

export const GENERATION_FRAGMENT = gql(`
  fragment GenerationFragment on AiAnimatedElementsGeneration {
    _id
    progress
    generationId
    inputFileUrl
    inputBucketInfo {
      bucketName
      filePath
    }
    status
    feedback {
      rating
      message
    }
    presets {
      aspectRatio
      imagePosition
      margin
      customPrompt
      coordinates {
        x
        y
      }
      backgroundColor
      presetId
      modelName
      task {
        status
        parsedResult
        jsonPayload
        intermediateResults {
          url
        }
        thumbnailData {
          thumbHash
          thumbnailUrl
        }
      }
    }
  }
`);

export const GENERATION_COMPLETE_FRAGMENT = gql(`
  fragment GenerationCompleteFragment on AiAnimatedElementsGeneration {
    _id
    progress
    generationId
    inputFileUrl
    inputBucketInfo {
      bucketName
      filePath
    }
    status
    feedback {
      rating
      message
    }
    presets {
      aspectRatio
      imagePosition
      margin
      customPrompt
      coordinates {
        x
        y
      }
      backgroundColor
      presetId
      modelName
      task {
        status
        parsedResult
        jsonPayload
        intermediateResults {
          url
        }
        outputVideo {
          url
          hasWatermark
        }
        thumbnailData {
          thumbHash
          thumbnailUrl
        }
      }
    }
  }
`);

export const GET_GENERATION = gql(`
  query GetAiAnimatedElementsGeneration($generationId: ID!) {
    getAiAnimatedElementsGeneration(generationId: $generationId) {
      ...GenerationFragment
    }
  }
`);

export function useGenerationQuery({ generationId }: { generationId: string }) {
  return useQuery(GET_GENERATION, { variables: { generationId } });
}

const CANCEL_GENERATION = gql(`
  mutation CancelGeneration($generationId: ID!) {
    cancelGeneration(generationId: $generationId) {
      generationId
      status
    }
  }
`);

export function useCancelGeneration(generationId: string) {
  return useMutation(CANCEL_GENERATION, {
    variables: { generationId },
    optimisticResponse: {
      __typename: "Mutation",
      cancelGeneration: {
        generationId,
        status: AiAnimatedElementsGenerationStatus.Canceled,
      },
    },
    update(cache, { data }) {
      const prevdata = cache.readQuery({ query: MY_GENERATIONS });
      cache.writeQuery({
        query: MY_GENERATIONS,
        data: {
          ...prevdata,
          myAiGenerations: {
            ...prevdata?.myAiGenerations,
            data: prevdata?.myAiGenerations.data.map((g) =>
              (g as AiAnimatedElementsGeneration).generationId === generationId
                ? {
                    ...g,
                    status: data?.cancelGeneration.status,
                  }
                : g,
            ),
          },
        },
      });
    },
  });
}

const CREATE_VARIATION = gql(`
  mutation CreateGenerationVariation($generationId: ID!) {
    createGenerationVariation(generationId: $generationId) {
      ...GenerationFragment
    }
  }
`);

export function useCreateVariation() {
  return useMutation(CREATE_VARIATION, {
    update(client, { data }) {
      const generation = data?.createGenerationVariation;
      if (generation) {
        removeOneCredit(client);
        // Add generation to query
        const prevdata = client.readQuery({ query: MY_GENERATIONS });
        client.writeQuery({
          query: MY_GENERATIONS,
          overwrite: true,
          data: {
            ...prevdata,
            myAiGenerations: {
              ...prevdata?.myAiGenerations,
              data: [generation, ...(prevdata?.myAiGenerations.data ?? [])],
            },
          },
        });
      }
    },
  });
}

const CREATE_GENERATION = gql(`
  mutation CreateAiAnimatedElementsWithPresets(
    $inputFileUrl: String!
    $presets: [PresetConfig!]
    $inputBucketInfo: BucketInfoInput
  ) {
    createAiAnimatedElementsWithPresets(
      inputFileUrl: $inputFileUrl
      presets: $presets
      inputBucketInfo: $inputBucketInfo
    ) {
      ...GenerationFragment
    }
  }
`);

export function useCreateGeneration() {
  return useMutation(CREATE_GENERATION, {
    update(cache, { data }) {
      removeOneCredit(cache);
      const prevdata = cache.readQuery({ query: MY_GENERATIONS });
      if (data?.createAiAnimatedElementsWithPresets)
        cache.writeQuery({
          query: MY_GENERATIONS,
          data: {
            myAiGenerations: {
              ...prevdata?.myAiGenerations,
              data: [
                data?.createAiAnimatedElementsWithPresets as AiAnimatedElementsGeneration,
                ...(prevdata?.myAiGenerations.data ?? []),
              ],
            },
          },
        });
    },
  });
}

const CREATE_GENERATION_PREVIEW = gql(`
  mutation CreateAiAnimatedElementsWithPresetsPreview(
    $inputFileUrl: String!
    $presets: [PresetConfig!]
    $inputBucketInfo: BucketInfoInput
    $needsIntermediateFramesValidation: IntermediateFramesValidationInput
  ) {
    createAiAnimatedElementsWithPresets(
      inputFileUrl: $inputFileUrl
      presets: $presets
      inputBucketInfo: $inputBucketInfo,
      needsIntermediateFramesValidation: $needsIntermediateFramesValidation
    ) {
      ...GenerationFragment
    }
  }
`);

export function useCreateGenerationPreview() {
  return useMutation(CREATE_GENERATION_PREVIEW);
}

const START_PREVIEW_GENERATION = gql(`
  mutation StartPendingGeneration($generationId: ID!, $previewUrl: String!) {
    startPendingGeneration(generationId: $generationId, previewUrl: $previewUrl) {
      ...GenerationFragment
    }
  }
`);

export function useStartPreviewGeneration() {
  return useMutation(START_PREVIEW_GENERATION, {
    update(cache, { data }) {
      removeOneCredit(cache);
      const prevdata = cache.readQuery({ query: MY_GENERATIONS });
      if (data?.startPendingGeneration && prevdata)
        cache.writeQuery({
          query: MY_GENERATIONS,
          data: {
            myAiGenerations: {
              ...prevdata?.myAiGenerations,
              data: [
                data.startPendingGeneration as AiAnimatedElementsGeneration,
                ...(prevdata?.myAiGenerations.data ?? []),
              ],
            },
          },
        });
    },
  });
}

const REGENERATE_PREVIEW = gql(`
  mutation RegeneratePreviewFrames($generationId: ID!, $previewCounts: Int!) {
    regeneratePreviewFrames(generationId: $generationId, previewCounts: $previewCounts) {
      ...GenerationFragment
    }
  }
`);

export function useRegeneratePreview() {
  return useMutation(REGENERATE_PREVIEW);
}

const DELETE_GENERATION = gql(`
  mutation DeleteAiGeneration($generationId: ID!) {
    deleteAiGeneration(generationId: $generationId) {
      generationId
    }
  }
`);

export function useDeleteGeneration({
  generationId,
}: {
  generationId: string;
}) {
  return useMutation(DELETE_GENERATION, {
    variables: { generationId },
    optimisticResponse: {
      __typename: "Mutation",
      deleteAiGeneration: {
        generationId,
      },
    },
    update(client, { data }) {
      const generationId = data?.deleteAiGeneration.generationId;
      if (generationId) {
        // Remove generation from query
        const prevdata = client.readQuery({ query: MY_GENERATIONS });
        client.writeQuery({
          query: MY_GENERATIONS,
          overwrite: true,
          data: {
            ...prevdata,
            myAiGenerations: {
              ...prevdata?.myAiGenerations,
              data: prevdata?.myAiGenerations.data.filter(
                (g) =>
                  (g as AiAnimatedElementsGeneration).generationId !==
                  generationId,
              ),
            },
          },
        });
      }
    },
  });
}

export const isGenerationProcessing = (
  generation: AiAnimatedElementsGeneration,
) => {
  const status = getGenerationStatus(generation);
  return [
    GenerationStatus.Preparing,
    GenerationStatus.Queued,
    GenerationStatus.Generating,
  ].includes(status);
};

export enum GenerationStatus {
  Preparing = "preparing",
  Queued = "queued",
  Complete = "complete",
  Generating = "processing",
  Canceled = "canceled",
  Error = "error",
}

export function getGenerationStatus(
  generation: AiAnimatedElementsGeneration,
): GenerationStatus {
  if (
    [
      AiAnimatedElementsGenerationStatus.Error,
      AiAnimatedElementsGenerationStatus.TasksCompletedWithErrors,
      AiAnimatedElementsGenerationStatus.TasksPartialError,
    ].includes(generation.status)
  ) {
    return GenerationStatus.Error;
  }
  if (generation.status === AiAnimatedElementsGenerationStatus.Canceled) {
    return GenerationStatus.Canceled;
  }
  if (
    [
      AiAnimatedElementsGenerationStatus.TasksPartialCompleted,
      AiAnimatedElementsGenerationStatus.TasksCompleted,
    ].includes(generation.status)
  ) {
    return GenerationStatus.Complete;
  }
  if (
    !generation?.presets?.[0].task ||
    generation?.presets?.[0].task?.status === TaskStatus.Preparing
  ) {
    return GenerationStatus.Preparing;
  }
  if (generation?.presets?.[0].task?.status === TaskStatus.Queued) {
    return GenerationStatus.Queued;
  }
  return GenerationStatus.Generating;
}

export function getGenerationPreview(generation: AiAnimatedElementsGeneration) {
  const payload = JSON.parse(generation.presets[0].task?.jsonPayload ?? "") as {
    input: { promptImageAlternatives?: { uri?: string }[] };
  };
  const alternatives = payload.input.promptImageAlternatives;

  return {
    generationId: generation.generationId,
    url: alternatives?.[alternatives?.length - 1]?.uri,
  };
}
