/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment GenerationFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputFileUrl\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    feedback {\n      rating\n      message\n    }\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      modelName\n      task {\n        status\n        parsedResult\n        jsonPayload\n        intermediateResults {\n          url\n        }\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n": types.GenerationFragmentFragmentDoc,
    "\n  fragment GenerationCompleteFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputFileUrl\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    feedback {\n      rating\n      message\n    }\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      modelName\n      task {\n        status\n        parsedResult\n        jsonPayload\n        intermediateResults {\n          url\n        }\n        outputVideo {\n          url\n          hasWatermark\n        }\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n": types.GenerationCompleteFragmentFragmentDoc,
    "\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n": types.GetAiAnimatedElementsGenerationDocument,
    "\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n": types.CancelGenerationDocument,
    "\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n": types.CreateGenerationVariationDocument,
    "\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      ...GenerationFragment\n    }\n  }\n": types.CreateAiAnimatedElementsWithPresetsDocument,
    "\n  mutation CreateAiAnimatedElementsWithPresetsPreview(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n    $needsIntermediateFramesValidation: IntermediateFramesValidationInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo,\n      needsIntermediateFramesValidation: $needsIntermediateFramesValidation\n    ) {\n      ...GenerationFragment\n    }\n  }\n": types.CreateAiAnimatedElementsWithPresetsPreviewDocument,
    "\n  mutation StartPendingGeneration($generationId: ID!, $previewUrl: String!) {\n    startPendingGeneration(generationId: $generationId, previewUrl: $previewUrl) {\n      ...GenerationFragment\n    }\n  }\n": types.StartPendingGenerationDocument,
    "\n  mutation RegeneratePreviewFrames($generationId: ID!, $previewCounts: Int!) {\n    regeneratePreviewFrames(generationId: $generationId, previewCounts: $previewCounts) {\n      ...GenerationFragment\n    }\n  }\n": types.RegeneratePreviewFramesDocument,
    "\n  mutation DeleteAiGeneration($generationId: ID!) {\n    deleteAiGeneration(generationId: $generationId) {\n      generationId\n    }\n  }\n": types.DeleteAiGenerationDocument,
    "\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        ...GenerationCompleteFragment\n      }\n      nextCursor\n    }\n  }\n": types.MyGenerationsDocument,
    "\n  query GenerateAiAnimatedPictureInputFromBase64($base64Data: String!, $fileName: String!) {\n  generateAiAnimatedPictureInputFromBase64(base64Data: $base64Data, fileName: $fileName) {\n    removedBackgroundPictureUrl\n    bucketName\n    filePath\n    backgroundColorToUse\n  }\n}\n": types.GenerateAiAnimatedPictureInputFromBase64Document,
    "\n  query GetGenerations($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationFragment\n    }\n  }\n": types.GetGenerationsDocument,
    "\n  query GetGenerationsComplete($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationCompleteFragment\n    }\n  }\n": types.GetGenerationsCompleteDocument,
    "\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n": types.NotifyEmailOnGenerationCompleteDocument,
    "\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n": types.SendFeedbackForGenerationDocument,
    "\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n": types.GetUserProfilePictureUploadInfoDocument,
    "\n  query GetAiRevealsPlanPrices {\n    getAiRevealsPlanPrices {\n      tokensGiven\n      price\n      periodicity\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n": types.GetAiRevealsPlanPricesDocument,
    "\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        modelData {\n          modelInfo {\n            modelName\n          }\n        }\n        presetId\n        previewVideoUrl\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n      nextCursor\n    }\n  }\n": types.PresetsDocument,
    "\n  query GetRevealsCategoriesWithPresets($filters: GetRevealsCategoriesWithPresetsFilters) {\n    getRevealsCategoriesWithPresets(filters: $filters) {\n      categories {\n        categoryId\n        label\n        presets {\n          presetId\n          previewVideoUrl\n          thumbnailData {\n            thumbHash\n            thumbnailUrl\n          }\n        }\n      }\n      nextCursor\n    }\n  }\n": types.GetRevealsCategoriesWithPresetsDocument,
    "\n  query GetPromptPresets($imageUrl: String!) {\n    getPromptPresetsFromImage(imageUrl: $imageUrl) {\n      object\n      presets {\n        short\n        long\n      }\n    }\n  }\n": types.GetPromptPresetsDocument,
    "\n  query GetPresetSuggestionsFromImage($imageUrl: String!) {\n    getPresetSuggestionsFromImage(imageUrl: $imageUrl) {\n      object\n      long\n      short\n      suggestions {\n        presetId\n        previewVideoUrl\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n": types.GetPresetSuggestionsFromImageDocument,
    "\n  query GetProfile {\n    getProfile {\n      uid\n      email\n      profilePictureUrl\n      name\n      aiCredits\n      isAiSubscriber\n    }\n  }\n": types.GetProfileDocument,
    "\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  ": types.RemoveProfilePictureDocument,
    "\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  ": types.SetNameDocument,
    "\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n": types.GetAiTokensPlansDocument,
    "\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n": types.GetOneOffCheckoutSessionDocument,
    "\n  mutation HandleAiCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleAiCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n": types.HandleAiCheckoutSessionSuccessDocument,
    "\n  query GetStripePortal($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n": types.GetStripePortalDocument,
    "\n  query GetSubscriptionInfo {\n    getProfile {\n      aiSubscriberInfo {\n        hasActiveSubscription\n        store\n      }\n    }\n  }\n": types.GetSubscriptionInfoDocument,
    "\n  query GetAiCheckoutSession($aiCheckoutSessionInput: AiCheckoutSessionInput!) {\n    getAiCheckoutSession(aiCheckoutSessionInput: $aiCheckoutSessionInput) {\n      sessionId\n    }\n  }\n": types.GetAiCheckoutSessionDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GenerationFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputFileUrl\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    feedback {\n      rating\n      message\n    }\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      modelName\n      task {\n        status\n        parsedResult\n        jsonPayload\n        intermediateResults {\n          url\n        }\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment GenerationFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputFileUrl\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    feedback {\n      rating\n      message\n    }\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      modelName\n      task {\n        status\n        parsedResult\n        jsonPayload\n        intermediateResults {\n          url\n        }\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GenerationCompleteFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputFileUrl\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    feedback {\n      rating\n      message\n    }\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      modelName\n      task {\n        status\n        parsedResult\n        jsonPayload\n        intermediateResults {\n          url\n        }\n        outputVideo {\n          url\n          hasWatermark\n        }\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment GenerationCompleteFragment on AiAnimatedElementsGeneration {\n    _id\n    progress\n    generationId\n    inputFileUrl\n    inputBucketInfo {\n      bucketName\n      filePath\n    }\n    status\n    feedback {\n      rating\n      message\n    }\n    presets {\n      aspectRatio\n      imagePosition\n      margin\n      customPrompt\n      coordinates {\n        x\n        y\n      }\n      backgroundColor\n      presetId\n      modelName\n      task {\n        status\n        parsedResult\n        jsonPayload\n        intermediateResults {\n          url\n        }\n        outputVideo {\n          url\n          hasWatermark\n        }\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAiAnimatedElementsWithPresetsPreview(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n    $needsIntermediateFramesValidation: IntermediateFramesValidationInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo,\n      needsIntermediateFramesValidation: $needsIntermediateFramesValidation\n    ) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAiAnimatedElementsWithPresetsPreview(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n    $needsIntermediateFramesValidation: IntermediateFramesValidationInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo,\n      needsIntermediateFramesValidation: $needsIntermediateFramesValidation\n    ) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation StartPendingGeneration($generationId: ID!, $previewUrl: String!) {\n    startPendingGeneration(generationId: $generationId, previewUrl: $previewUrl) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  mutation StartPendingGeneration($generationId: ID!, $previewUrl: String!) {\n    startPendingGeneration(generationId: $generationId, previewUrl: $previewUrl) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RegeneratePreviewFrames($generationId: ID!, $previewCounts: Int!) {\n    regeneratePreviewFrames(generationId: $generationId, previewCounts: $previewCounts) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  mutation RegeneratePreviewFrames($generationId: ID!, $previewCounts: Int!) {\n    regeneratePreviewFrames(generationId: $generationId, previewCounts: $previewCounts) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteAiGeneration($generationId: ID!) {\n    deleteAiGeneration(generationId: $generationId) {\n      generationId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteAiGeneration($generationId: ID!) {\n    deleteAiGeneration(generationId: $generationId) {\n      generationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        ...GenerationCompleteFragment\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        ...GenerationCompleteFragment\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GenerateAiAnimatedPictureInputFromBase64($base64Data: String!, $fileName: String!) {\n  generateAiAnimatedPictureInputFromBase64(base64Data: $base64Data, fileName: $fileName) {\n    removedBackgroundPictureUrl\n    bucketName\n    filePath\n    backgroundColorToUse\n  }\n}\n"): (typeof documents)["\n  query GenerateAiAnimatedPictureInputFromBase64($base64Data: String!, $fileName: String!) {\n  generateAiAnimatedPictureInputFromBase64(base64Data: $base64Data, fileName: $fileName) {\n    removedBackgroundPictureUrl\n    bucketName\n    filePath\n    backgroundColorToUse\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetGenerations($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationFragment\n    }\n  }\n"): (typeof documents)["\n  query GetGenerations($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetGenerationsComplete($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationCompleteFragment\n    }\n  }\n"): (typeof documents)["\n  query GetGenerationsComplete($generationsIds: [String!]!) {\n    getGenerations(generationsIds: $generationsIds) {\n      ...GenerationCompleteFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n"): (typeof documents)["\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n"): (typeof documents)["\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n"): (typeof documents)["\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAiRevealsPlanPrices {\n    getAiRevealsPlanPrices {\n      tokensGiven\n      price\n      periodicity\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAiRevealsPlanPrices {\n    getAiRevealsPlanPrices {\n      tokensGiven\n      price\n      periodicity\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        modelData {\n          modelInfo {\n            modelName\n          }\n        }\n        presetId\n        previewVideoUrl\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        modelData {\n          modelInfo {\n            modelName\n          }\n        }\n        presetId\n        previewVideoUrl\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRevealsCategoriesWithPresets($filters: GetRevealsCategoriesWithPresetsFilters) {\n    getRevealsCategoriesWithPresets(filters: $filters) {\n      categories {\n        categoryId\n        label\n        presets {\n          presetId\n          previewVideoUrl\n          thumbnailData {\n            thumbHash\n            thumbnailUrl\n          }\n        }\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query GetRevealsCategoriesWithPresets($filters: GetRevealsCategoriesWithPresetsFilters) {\n    getRevealsCategoriesWithPresets(filters: $filters) {\n      categories {\n        categoryId\n        label\n        presets {\n          presetId\n          previewVideoUrl\n          thumbnailData {\n            thumbHash\n            thumbnailUrl\n          }\n        }\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPromptPresets($imageUrl: String!) {\n    getPromptPresetsFromImage(imageUrl: $imageUrl) {\n      object\n      presets {\n        short\n        long\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPromptPresets($imageUrl: String!) {\n    getPromptPresetsFromImage(imageUrl: $imageUrl) {\n      object\n      presets {\n        short\n        long\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPresetSuggestionsFromImage($imageUrl: String!) {\n    getPresetSuggestionsFromImage(imageUrl: $imageUrl) {\n      object\n      long\n      short\n      suggestions {\n        presetId\n        previewVideoUrl\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPresetSuggestionsFromImage($imageUrl: String!) {\n    getPresetSuggestionsFromImage(imageUrl: $imageUrl) {\n      object\n      long\n      short\n      suggestions {\n        presetId\n        previewVideoUrl\n        thumbnailData {\n          thumbHash\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProfile {\n    getProfile {\n      uid\n      email\n      profilePictureUrl\n      name\n      aiCredits\n      isAiSubscriber\n    }\n  }\n"): (typeof documents)["\n  query GetProfile {\n    getProfile {\n      uid\n      email\n      profilePictureUrl\n      name\n      aiCredits\n      isAiSubscriber\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  "): (typeof documents)["\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n"): (typeof documents)["\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n"): (typeof documents)["\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation HandleAiCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleAiCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n"): (typeof documents)["\n  mutation HandleAiCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleAiCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStripePortal($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n"): (typeof documents)["\n  query GetStripePortal($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSubscriptionInfo {\n    getProfile {\n      aiSubscriberInfo {\n        hasActiveSubscription\n        store\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSubscriptionInfo {\n    getProfile {\n      aiSubscriberInfo {\n        hasActiveSubscription\n        store\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAiCheckoutSession($aiCheckoutSessionInput: AiCheckoutSessionInput!) {\n    getAiCheckoutSession(aiCheckoutSessionInput: $aiCheckoutSessionInput) {\n      sessionId\n    }\n  }\n"): (typeof documents)["\n  query GetAiCheckoutSession($aiCheckoutSessionInput: AiCheckoutSessionInput!) {\n    getAiCheckoutSession(aiCheckoutSessionInput: $aiCheckoutSessionInput) {\n      sessionId\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;