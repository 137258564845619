import { Text } from "@archery-inc/design-system";
import { motion } from "framer-motion";
import { version } from "../../package.json";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";

export default function OnboardingLayout() {
  return (
    <div className="flex h-screen w-screen">
      <motion.div
        initial={{ x: "-100%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: "spring", mass: 0.2, stiffness: 70 }}
        className="h-full w-2/5 justify-center items-center hidden md:flex bg-black-0"
      >
        <video
          muted
          autoPlay
          loop
          className="w-full"
          src="https://public-files-cdn.mojo.video/assets/ai/previews/landing/paywall-2.mp4"
        />
      </motion.div>
      <motion.div
        initial={{ y: 150, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", mass: 0.4, stiffness: 50 }}
        className="relative px-8 h-full flex md:w-3/5 w-full justify-center items-center"
      >
        <div
          className="absolute bottom-0 left-0 rounded-6 px-6 py-4"
          style={{
            backdropFilter: "blur(10px)",
          }}
        >
          <Text variant="body-5" className="text-grey-8">
            v{version}
          </Text>
        </div>
        <div className="flex flex-col max-w-lg pb-20 w-full">
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </div>
      </motion.div>
    </div>
  );
}
