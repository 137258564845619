import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Icon, Text, Button } from "@archery-inc/design-system";
import { useHandleCheckoutSessionSuccess } from "../api/graphql/subscription";
import { amplitude, trackFbq } from "../api/third-party";

export default function SubscriptionStatusPage() {
  const navigate = useNavigate();
  const { status } = useParams();
  const [searchParams] = useSearchParams();
  const checkoutSessionId = searchParams.get("session_id");

  const [handleCheckoutSessionSuccess, { loading }] =
    useHandleCheckoutSessionSuccess();

  useEffect(() => {
    amplitude.logEvent(`Web:Client:Subscription:Status:View`, {
      status,
      fromAiReveal: true,
      checkoutSessionId,
    });

    if (status === "success" && checkoutSessionId) {
      void handleCheckoutSessionSuccess({
        variables: { checkoutSessionId },
      })
        .then(() => {
          trackFbq("PurchaseSuccess");
          navigate("/");
        })
        .catch(() => {
          // noop
        });
    }
  }, [handleCheckoutSessionSuccess, navigate, checkoutSessionId, status]);

  return loading ? (
    <div className="flex items-center">
      <div className="p-2">
        <FormattedMessage id="finalizing_purchase" />
      </div>
      <Icon icon="spinner" />
    </div>
  ) : (
    <>
      <Text weight="medium" variant="title-2">
        <FormattedMessage id="subscribe_error_generic" />
      </Text>
      <div className="flex justify-center mt-8">
        <Button href="/" size="l" leftIcon="arrow_full_left">
          <FormattedMessage id="subscribe_pro_back" />
        </Button>
      </div>
    </>
  );
}
