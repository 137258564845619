import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { updateMetadata } from "firebase/storage";
import { StateCreator } from "zustand";
import {
  AiAnimatedElementsGeneration,
  AiRevealImagePosition,
  BucketInfoInput,
  CreateAiAnimatedElementsWithPresetsMutation,
  Exact,
  InputMaybe,
  IntermediateFramesValidationInput,
  PresetConfig,
  RevealsModelName,
  Scalars,
} from "../../../__generated__/graphql";
import {
  downloadFromUrl,
  getFileMetadata,
  getFileRef,
  getFileUrl,
} from "../../../api/firebaseStorage";
import { getGenerationPreview } from "../../../api/graphql/generation";
import { amplitude } from "../../../api/third-party";
import {
  AiRevealFormat,
  compressImage,
  getAspectRatio,
  getFormat,
} from "../../../common/utils/image";
import { isUUID } from "../../../common/utils/uuid";

export type Step = "photo-upload" | "preset-selection";

export type Model = "animated-controlnets" | "runway";

export interface FileInfo {
  bucketName?: string;
  filePath: string;
  base64?: string;
}

interface State {
  step: Step;
  format: AiRevealFormat;
  imagePosition: AiRevealImagePosition;
  margin: number;
  model: Model;
  x: number | null;
  y: number | null;
  originalFileInfo: FileInfo | null;
  removedBackgroundFileInfo: FileInfo | null;
  usedFileInfo: "original" | "no-background";
  backgroundColor: string | null;
  defaultBackgroundColor: string | null;
}

type CreateGeneration = (
  options?: MutationFunctionOptions<
    CreateAiAnimatedElementsWithPresetsMutation,
    Exact<{
      inputFileUrl: Scalars["String"]["input"];
      presets?: InputMaybe<PresetConfig[] | PresetConfig>;
      inputBucketInfo?: InputMaybe<BucketInfoInput>;
      needsIntermediateFramesValidation?: InputMaybe<IntermediateFramesValidationInput>;
    }>
  >,
) => Promise<FetchResult<CreateAiAnimatedElementsWithPresetsMutation>>;

interface Actions {
  initFromUrlLocation: () => Promise<void>;
  initFromGeneration: (
    generation: AiAnimatedElementsGeneration,
  ) => Promise<void>;
  setModel: (model: Model) => void;
  reset: () => void;
  removeImage: () => void;
  replaceImage: (file: File) => Promise<void>;
  uploadImage: (file: File) => Promise<string>;
  setRemovedBackgroundData: (
    data: FileInfo & { url: string; color?: string },
  ) => Promise<void>;
  setPositionPredefined: (position: AiRevealImagePosition) => void;
  setCustomPosition: (x: number, y: number) => void;
  setBackgroundColor: (color: string) => void;
  setMargin: (margin: number) => void;
  setFormat: (format: AiRevealFormat) => void;
  resetBackgroundColor: () => void;
  toggleUsedFileInfo: () => void;
  createGeneration: (
    mutate: CreateGeneration,
    presetId: string,
    presetData?: { content: string },
  ) => Promise<void>;
  getGenerationPreview: (
    mutate: CreateGeneration,
    presetId: string,
    presetData?: { content: string },
    suggestion?: { object: string; short: string; long: string },
  ) => Promise<{ url?: string; generationId: string }>;
}

export type AiStore = State & Actions;

const defaultState: State = {
  step: "photo-upload",
  format: AiRevealFormat.InstagramReel,
  imagePosition: AiRevealImagePosition.Center,
  margin: getDefaultMargin("runway"),
  model: "runway",
  usedFileInfo: "original",
  removedBackgroundFileInfo: null,
  backgroundColor: null,
  defaultBackgroundColor: null,
  originalFileInfo: null,
  x: null,
  y: null,
};

export const createAiStore: StateCreator<AiStore> = (set, get) => ({
  ...defaultState,
  setModel(model: Model) {
    set({ model });
  },
  async initFromUrlLocation() {
    const searchParams = new URLSearchParams(window.location.search);
    const encodedFileData = searchParams.get("fileData");
    if (!encodedFileData) {
      return;
    }

    const decodedDataJson = base64UrlDecode(encodedFileData);
    const decodedData = JSON.parse(decodedDataJson) as {
      url: string;
      bucketName: string;
      filePath: string;
      backgroundColorToUse: string;
      model: Model;
    };

    if (!decodedData.url || !decodedData.bucketName || !decodedData.filePath) {
      return;
    }

    const originalFileInfo = await getFileInfoFromMeta(decodedData);
    set({
      step: "preset-selection",
      format:
        (searchParams.get("format") as AiRevealFormat) ??
        AiRevealFormat.InstagramReel,
      imagePosition: AiRevealImagePosition.Center,
      x: null,
      y: null,
      margin: getDefaultMargin(decodedData.model),
      model: decodedData.model,
      usedFileInfo: "no-background",
      removedBackgroundFileInfo: {
        bucketName: decodedData.bucketName,
        filePath: decodedData.filePath,
      },
      originalFileInfo,
      backgroundColor: null,
      defaultBackgroundColor:
        decodedData.model === "runway"
          ? null
          : decodedData.backgroundColorToUse,
    });
  },
  async initFromGeneration(generation: AiAnimatedElementsGeneration) {
    const preset = generation.presets[0];

    const removedBackgroundFileInfo = {
      bucketName: generation.inputBucketInfo!.bucketName,
      filePath: generation.inputBucketInfo!.filePath,
    };

    const originalFileInfo = await getFileInfoFromMeta(
      removedBackgroundFileInfo,
    );

    const model =
      preset.modelName === RevealsModelName.Runway
        ? "runway"
        : "animated-controlnets";
    const bgColor = model === "runway" ? null : preset.backgroundColor;
    set({
      step: "preset-selection",
      format: getFormat(preset.aspectRatio, model),
      imagePosition: preset.imagePosition,
      margin: preset.margin!,
      model,
      x: preset.coordinates?.x,
      y: preset.coordinates?.y,
      removedBackgroundFileInfo,
      originalFileInfo,
      usedFileInfo: "no-background",
      backgroundColor: bgColor,
      defaultBackgroundColor: bgColor,
    });
  },
  async uploadImage(file) {
    const originalFileInfo = await compressImage(file);
    amplitude.logEvent("Web:Client:AiReveals:RemoveBackground:Start", {
      filepath: originalFileInfo.filePath,
    });
    set({
      step: "preset-selection",
      usedFileInfo: "original",
      originalFileInfo,
    });
    return `data:image/webp;base64,${originalFileInfo.base64}`;
  },
  removeImage() {
    amplitude.logEvent("Web:Client:AiReveals:RemoveImage");
    set(defaultState);
  },
  async replaceImage(file) {
    const originalFileInfo = await compressImage(file);
    amplitude.logEvent("Web:Client:AiReveals:ReplaceImage", {
      filepath: originalFileInfo.filePath,
    });
    set({
      usedFileInfo: "original",
      originalFileInfo,
      imagePosition: AiRevealImagePosition.Center,
      removedBackgroundFileInfo: null,
    });
  },
  setPositionPredefined(position) {
    set({ imagePosition: position, x: undefined, y: undefined });
  },
  setCustomPosition(x, y) {
    set({ imagePosition: AiRevealImagePosition.Custom, x, y });
  },
  setMargin(margin) {
    set({ margin });
  },
  setFormat(format) {
    set({ format });
  },
  setBackgroundColor(color) {
    set({ backgroundColor: color });
  },
  resetBackgroundColor() {
    set({ backgroundColor: undefined });
  },
  toggleUsedFileInfo() {
    set((state) => ({
      ...state,
      usedFileInfo:
        state.usedFileInfo === "original" ? "no-background" : "original",
    }));
  },
  async setRemovedBackgroundData(data) {
    const originalFileInfo = await getFileInfoFromMeta(data);
    set((state) => {
      return {
        ...state,
        usedFileInfo: "no-background",
        imagePosition: AiRevealImagePosition.Center,
        defaultBackgroundColor: state.model === "runway" ? null : data.color,
        removedBackgroundFileInfo: {
          bucketName: data.bucketName,
          filePath: data.filePath,
        },
        originalFileInfo: {
          ...originalFileInfo,
          base64: state.originalFileInfo?.base64,
        },
      };
    });
  },
  async createGeneration(mutate, presetId, presetData) {
    const aiState = get();
    const fileInfo =
      aiState.usedFileInfo === "no-background"
        ? aiState.removedBackgroundFileInfo
        : aiState.originalFileInfo;

    const inputBucketInfo = {
      bucketName: fileInfo!.bucketName!,
      filePath: fileInfo!.filePath,
    };

    const newPreset = {
      presetId,
      customPrompt:
        presetData?.content.replace(/"/g, "'").replace(/\n/g, " ") ?? null,
      margin: aiState.margin,
      aspectRatio: getAspectRatio(aiState.format, aiState.model),
      imagePosition: aiState.imagePosition,
      backgroundColor: aiState.backgroundColor,
      coordinates:
        aiState.x && aiState.y ? { x: aiState.x, y: aiState.y } : null,
    };

    const url = (await getFileUrl(fileInfo!.bucketName!, fileInfo!.filePath))!;

    await mutate({
      variables: {
        inputFileUrl: url,
        inputBucketInfo,
        presets: [
          {
            ...newPreset,
            modelName: aiState.model,
            seeds: Array.from({ length: 1 }, () =>
              Math.floor(Math.random() * 1000000),
            ),
          },
        ],
      },
    });
    set(defaultState);
  },
  reset() {
    set(defaultState);
  },
  async getGenerationPreview(mutate, presetId, presetData, suggestion) {
    const aiState = get();
    const fileInfo =
      aiState.usedFileInfo === "no-background"
        ? aiState.removedBackgroundFileInfo
        : aiState.originalFileInfo;

    const inputBucketInfo = {
      bucketName: fileInfo!.bucketName!,
      filePath: fileInfo!.filePath,
    };

    const newPreset = {
      presetId,
      customPrompt: presetData?.content.replace(/"/g, "'").replace(/\n/g, " "),
      margin: aiState.margin,
      aspectRatio: getAspectRatio(aiState.format, aiState.model),
      imagePosition: aiState.imagePosition,
      backgroundColor: aiState.backgroundColor,
      coordinates:
        aiState.x && aiState.y ? { x: aiState.x, y: aiState.y } : undefined,
    };
    const url = (await getFileUrl(fileInfo!.bucketName!, fileInfo!.filePath))!;
    if (suggestion) {
      await updateMetadata(
        getFileRef(fileInfo!.bucketName!, fileInfo!.filePath),
        {
          customMetadata: {
            suggestion: JSON.stringify({
              object: suggestion.object,
              short: suggestion.short,
              long: suggestion.long,
            }),
          },
        },
      );
    }
    const { data } = await mutate({
      variables: {
        inputFileUrl: url,
        inputBucketInfo,
        needsIntermediateFramesValidation: {
          numberOfVariations: 1,
        },
        presets: [
          {
            ...newPreset,
            modelName: aiState.model,
            seeds: Array.from({ length: 1 }, () =>
              Math.floor(Math.random() * 1000000),
            ),
          },
        ],
      },
    });
    return getGenerationPreview(
      data?.createAiAnimatedElementsWithPresets as AiAnimatedElementsGeneration,
    );
  },
});

function base64UrlDecode(input: string) {
  let base64 = input.replace(/-/g, "+").replace(/_/g, "/");
  while (base64.length % 4) {
    base64 += "=";
  }
  return atob(base64);
}

function getDefaultMargin(model: Model) {
  return model === "runway" ? 0.18 : 0.15;
}

async function getFileInfoFromMeta(info: FileInfo) {
  const fileInfo = { ...info };
  const meta = await getFileMetadata(fileInfo.bucketName!, fileInfo.filePath);
  const originalFilePath = meta?.customMetadata?.originalFilePathOnBucket;
  if (originalFilePath) {
    fileInfo.filePath = originalFilePath;
  }
  return fileInfo;
}

export function downloadGeneration(generation: AiAnimatedElementsGeneration) {
  const preset = generation.presets[0];
  if (!preset) return;

  const result = preset.task?.parsedResult as string[];
  amplitude.logEvent("Web:Client:AiReveals:Download", {
    model:
      preset.modelName === RevealsModelName.Runway ? "runway" : "controlnet",
    generationId: generation.generationId,
    presetId: isUUID(preset.presetId) ? "custom" : preset.presetId,
    dbPresetId: preset.presetId,
  });
  void downloadFromUrl(
    result[result.length - 1],
    `${preset.presetId}-${new Date().getTime()}`,
  );
}
